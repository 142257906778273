
import { defineComponent } from 'vue';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- State ---
import { mapActions } from 'vuex';
// --- Components ---
import TextField from '@/components/FormComponents/TextField.vue';
import Loader from '@/components/Loader.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { VBtn, VCard, VCardActions, VCol, VContainer, VForm, VRow, VSpacer } from 'vuetify/lib';

export default defineComponent({
    name: 'PasswordReset',
    components: {
        Loader,
        TextField,
        VBtn,
        VSpacer,
        VRow,
        VContainer,
        VCol,
        VCard,
        VForm,
        VCardActions
    },
    mixins: [formRulesMixin],

    data() {
        return {
            password: null as string | null,
            passwordConfirm: null as string | null,
            valid: true,
            loading: false,
            token: this.$route.params.token,
            sub: this.$route.query.sub // cognito user id, when provided
        };
    },

    created() {
        const token = this.$route.params.token;
        if (!token) {
            apiErrorAndDisplay.call(this, new Error('noPasswordResetToken'));
            return;
        }
    },

    methods: {
        ...mapActions('auth', ['resetPassword']),
        resetForm() {
            (this.$refs.form as any).reset();
        },
        async handleSubmit() {
            try {
                this.loading = true;
                if (this.password !== this.passwordConfirm) {
                    apiErrorAndDisplay.call(this, new Error('passwordsDontMatch'));
                    return;
                }
                await this.resetPassword({
                    resetPasswordToken: this.token,
                    password: this.password
                });
                this.resetForm();

                notify.call(this, {
                    title: this.$t('passwordReset.notifications.resetPassword.succesful.title'),
                    text: this.$t('passwordReset.notifications.resetPassword.succesful.content')
                });

                // Very weird but must use path for router to work here.
                this.$router.replace({
                    name: 'login'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
});
